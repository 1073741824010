.footer{
	background-color: black;
}

.section__padding{
	padding: 2rem 4rem;
}

.sb__footer{
	display: flex;
	flex-direction: column;
}

.sb__footer-links{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	text-align: left;
	margin-bottom: 1rem;
}

.sb__footer-links_div{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	color: white;
	width: 150px;
	margin: 1rem;
}

a, .contact{
	color: rgb(175,175,179);
	text-decoration: none;
}


.socialmedia{
	display:flex;
	flex-direction: row;
}

.socialmedia img{
	width:80%;
}

.sb__footer-links_div h4{
	font-size:14px;
	line-height: 17px;
	margin-bottom: 0.9rem;
}

.sb__footer-links_div p{
	font-size:12px;
	line-height: 15px;
	margin: 0.5rem 0;
	cursor: pointer;
}

.sb__footer-below{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 0.2rem;
}

.sb__footer-below-links{
	display: flex;
	flex-direction: row;
}

.sb__footer-below-links p{
	font-size: 13px;
	line-height: 15px;
	margin-left: 2rem;
	color:white;
	font-weight: 600;
}

hr{
	color: white !important;
	width:100%
}

.sb__footer-copyright p{
	font-size: 13px;
	line-height: 15px;
	color: rgb(255,255,255);
	font-weight: 600;
}

@media screen and (max-width:850px){
	.sb__footer-heading h1{
		font-size: 44px;
		line-height: 50px;
	}
}

@media screen and (max-width:550px){
	.sb__footer-heading h1{
		font-size: 34px;
		line-height: 42px;
	}

	.sb__footer-links div{
		margin: 1rem 0;
	}

	.sb__footer-btn p{
		font-size: 14px;
		line-height: 20px;
	}

	.sb__footer-below{
		flex-direction: column;
		justify-content: left;
	}

	.sb__footer-below-links{
		flex-direction: column;
	}

	.sb__footer-below-links p{
		margin-left: 0rem;
		margin-top: 1rem;
	}
}

@media screen and (max-width:400px){
	.sb__footer-heading h1{
		font-size: 27px;
		line-height: 38px;
	}
}