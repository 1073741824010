.radio-group__button-group {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  justify-content: center;
}

button.radio-group__button {
  font-size: 1rem;
  min-width: 100px;
  max-width: max-content;
  border: 1px solid #a6a6a6;
  border-radius: 15px;
  background-color: Transparent;
  padding: 0 16px;
  margin: 8px 8px;
}

button.radio-group__button[aria-checked="true"] {
  background-color: #9c27b0;
  border-width: 0;
}

button:hover {
  background-color: purple;
  border-width: 0;
}